code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-color: rgba(46, 46, 72, 1);
  text-align: center;
  color: #eaeaea;
  overflow: hidden;
}

body {
  margin: 0;
  color: #eaeaea;
  background-color: rgb(46,46,72)!important;
  background-image: url("./assets/images/square_base.png");
  background-position: left top;
  background-repeat: repeat-x;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.control-bar {
  position: absolute;
  bottom: 10vh;
  left: calc(50vw - 62px) !important;
  display: inline-flex;
  justify-content: space-between;
  width: 124px;
}

.tester-vid {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.transparent {
  opacity: 0.18;
}

.logo.transparent {
  background-image: url("./assets/images/TopModLogo.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 100%;
  margin: -40px auto auto;
  padding: 15% 0 0;
  width: 100vw;
  overflow: visible !important;
  position: absolute !important;
  left: 0;
}

.upper-input {
  text-transform: uppercase;
}
.upper-input:placeholder-shown {
  text-transform: none;
}

.mt-12 {
  margin-top: 10vh !important;
}

.modal-box {
background-image: url("./assets/images/ModalBackground.png");
background-position: left top;
background-repeat: repeat-x;
font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 75%;
height: 90%;
background-color: #565673;
border-radius: 10px;
box-shadow: 24;
padding: 4;
}

.modal-box.project-modal {
height: 65%;
}

.modal-box {
outline-width: 0!important;
}

.modal-box > .container > .row:nth-child(2) {
height: calc(100% - 160px);
}

.modal-box > .container {
height: 100%;
}

.modal-box .daqa-btn {
background-color: #202030!important;
color: #EAEAEA!important;
}

.modal-box .main-header {
text-align: left;
margin-top: 8px;
margin-left: 8px;
}

.modal-box #cam-select-list {
max-width: 50%;
margin: 0 auto;
margin-top: 12px;
}

.tailspin-loading-wrapper {
align-self: center;
}

.recording-settings-list {
display: flex;
}

.recording-settings-list > *:nth-child(1) {
font-size: medium;
font-weight: lighter;
}

.clip-save-btn {
margin-right: -50%;
width: calc(100% - 4px)!important;
margin-left: 4px;
}

.clip-description {
width: calc(100% - 4px)!important;
margin-left: -50%;
display: inline-block;
margin-right: 4px;
}

.tableWrapper {
max-height: 200px;
}

.truncate {
text-overflow: ellipsis;
white-space: nowrap;
}

.recording-settings-list > *:nth-child(2) {
font-size: medium;
font-weight: bolder;
}

.align-right {
text-align: right;
}

.align-left {
text-align: left;
}

video {
display: inline-flex;
align-self: center;
}

ul.naked {
text-decoration: none;
list-style: none;
text-align: left;
font-size: 16px;
padding-left: 0px;
font-weight: 700;
margin-left:-4px;
}

ul.naked > li {
padding: 8 8 8 24px;
}

ul.naked > li:hover {
background-color: #202030;
}

ul.naked >li.active {
background-color: #202030;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local(""),
  url("./assets/fonts/Inter-VariableFont_slnt,wght.ttf") format("ttf");
}

#file-button {
color: #eaeaea!important;
font-weight: 700;
font-family: "Inter", sans-serif!important;
text-transform: capitalize;
}

.home-inset {
margin-top: 2rem !important;
margin-bottom: 3rem !important;
}

.login-inset {
margin-top: 4rem !important;
margin-bottom: 3rem !important;
}

.padded-col-right {
margin-right: 5em!important;
}

.window-settings {
max-width: 100%!important;
--draggable: 1;
}

.window-settings .flex-container {
display: flex;
justify-content: space-between;
width: 100%;
height: 36px;
}

.window-settings .start {
align-self: flex-start;
}

.window-settings .end {
align-self: flex-end;
}

.window-settings, .window-settings > .row {
height:32px;
}

.window-settings > .row {
justify-content: center;
align-items: center;
}

/* .window-settings > .row > div {
justify-content: end!important;
} */

.window-settings > .row > .flex-container > div:nth-child(2) > div:nth-child(2) {
display: none;
}

.logo {
  display: block;
  width: 100%;
  height: 300px;
  background-size: contain;
  margin: -40px auto auto auto;
  padding: 15% 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/images/TopModLogo.png");
}

.daqa-btn {
background-color: #565673!important;
box-shadow: 0 8px 9px -4px #565673, 0 4px 18px 0 #565673;
}

.pauseplaysvg {
margin-top: 0px;
margin-bottom: 3px;
}

.inline-btn {
display:inline-block;
}

.errorMsg {
font-size: small;
font-style: italic;
}

#app {
  height: 100vh;
  text-align: center;
}

.result {
  height: 20px;
  line-height: 20px;
  margin: 1.5rem auto;
}
.input-box .btn {
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  border: none;
  margin: 0 0 0 20px;
  padding: 0 8px;
  cursor: pointer;
}
.input-box .btn:hover {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  color: #333333;
}

.input-box .input {
  border: none;
  border-radius: 3px;
  outline: none;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background-color: rgba(240, 240, 240, 1);
  -webkit-font-smoothing: antialiased;
}

.input-box .input:hover {
  border: none;
  background-color: rgba(255, 255, 255, 1);
}

.input-box .input:focus {
  border: none;
  background-color: rgba(255, 255, 255, 1);
}


.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}

.stopwatch-time {
  text-align: center;
  color: #dadada;
  font-size: calc(1.3rem + 0.6vw);
  margin-bottom: 0px!important;
  display: inline!important;
}

.stopwatch-buttons {
    display: flex;
    justify-content: center;
}

.stopwatch-buttons button {
    margin: 20px;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    color: #fff;
}

.stopwatch-buttons button:first-child {
    background-color: green;
}

.stopwatch-buttons button:last-child {
    background-color: red;
}

.recording-buttons > button {
  
}

.form-outline:has( > .collab-link-box) {
  display: inline-block!important;
  width: 75%!important;
  height: 36px!important;
  margin-right: 12px!important;
}

.collab-link-box {
  display: inline-block!important;
  height: 36px!important;
  margin-right: 12px!important;
}

.collab-copy-button {
  display: inline-block!important;
  width: auto!important;
}

.refresh-btn {
  margin-left: 15px!important;
  font-size: 19px!important;
  padding: 8px 15px 3px!important;
}

.app-select-dropdown {
  width: 180px!important;
  margin-left: -15px!important;
}

.recording-buttons > button:nth-child(2) {
  margin-left: 12px;
}

.modal-operation-buttons > button {
  max-width: 140px;
  min-width: 140px;
}

.modal-operation-buttons > button:nth-child(2) {
  margin-left: 12px;
}

.vl {
  border-right: 2px solid #eaeaea;
  padding: 0px!important;
}


/* LED System */


.re-centre {
  margin-left: -32px!important;
  margin-bottom: 12px!important;
}
.led-label {
  text-align: left;
}

.led {
  margin: 5px 16px 0 auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.led.green {
  background-color: #339900;
}

.led.orange {
  background-color: #ff7300;
}

.led.orange.enabled {
  background-color: #ff7300;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #ee7300 0 2px 12px;
}

.led.red {
  background-color: rgb(161, 0, 0);
}

.led.green.enabled {
  background-color: #ABFF00;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}

.led.red.enabled {
  background-color: #da0f00;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #e62012 0 2px 12px;
}

.modal-pane {
  max-height: 250px;
  overflow-y: auto;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
overflow: overlay;
scrollbar-gutter: stable;
}

/* total width */
.scrollbar::-webkit-scrollbar {
background-color: rgba(0,0,0,0);
width: 8px;
height: 8px;
z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
background-color: rgba(0,0,0,0);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
background-color: rgba(0,0,0,0);
border-radius:16px;
border:0px solid #ffffff;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
display:none;
}


/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
background-color: #a0a0a5;
border:0px solid #fff;
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:0px solid #f4f4f4
}